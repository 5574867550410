<template>
  <div class="billing-plans">
    <!--<PaymentMethods />-->
    <BillingAddress />
  </div>
</template>

<script>
import BillingAddress from "./BillingAddress.vue";
import PaymentMethods from "./PaymentMethods.vue";
export default {
  components: { BillingAddress, PaymentMethods },
  data() {
    return {
      //
    };
  },
};
</script>