<template>
  <div>
   <b-card no-body>
     <b-card-header>
       <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Personal Information
      </h4>
    </div>
     </b-card-header>
     <b-card-body>
    <b-form>
      <b-row>
        <!-- Field: Username -->
        <b-col cols="12" md="6">
          <b-form-group label="Username" label-for="username">
            <b-form-input id="username" v-model="userData.username" />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col cols="12" md="6">
          <b-form-group label="Full Name" label-for="full-name">
            <b-form-input id="full-name" v-model="userData.fullName" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="6">
          <b-form-group label="Email" label-for="email">
            <b-form-input :disabled="true" id="email" v-model="userData.email" type="email" />
          </b-form-group>
        </b-col>

   
       
       <!-- Field: Birth Date -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Birth Date"
            label-for="birth-date"
          >
            <flat-pickr
              v-model="userData.dob"
              class="form-control"
              :config="{ dateFormat: 'Y-m-d'}"
              placeholder="YYYY-MM-DD"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Website -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Website"
            label-for="website"
          >
            <b-form-input
              id="website"
              v-model="userData.website"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Language -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Language"
            label-for="language"
          >
            <v-select
              v-model="userData.user__language"
              :options="languageOptions"
              :clearable="false"
              input-id="language"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <div style="margin-top: 1rem;">
      <b-button
        @click="updateUserRecord(userData)"
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        Save Changes
      </b-button>
    </div>
    
    </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormRadioGroup
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import flatPickr from 'vue-flatpickr-component'
import useUsersList from "../users-list/useUsersList";
import { useUser } from "../useUser"

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BCardBody,
    BFormRadioGroup,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    flatPickr,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList();

    const { updateUserRecord } = useUser()

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    const permissionsData = [
      {
        module: "Admin",
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: "Staff",
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: "Author",
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: "Contributor",
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: "User",
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ];

    const languageOptions = [
      'English',
      'Spanish',
      'French',
      'Russian',
      'German',
      'Arabic',
      'Sanskrit',
    ]

    const genderOptions = [
      { text: 'Male', value: 'male' },
      { text: 'Female', value: 'female' },
    ]

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      permissionsData,

      languageOptions,
      genderOptions,

      updateUserRecord
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss'; 
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
