<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Current License
      </h5>
      <b-badge variant="light-primary">
        Single License
      </b-badge>
      <small class="text-muted w-100">July 22, 2021</small>
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-1">
        <li>
          <circle-icon size="0.6x" class="license_icons"></circle-icon>
          <span class="align-middle">You have right to use the product for your personal or client projects</span>
        </li>
        <li class="my-25">
          <circle-icon size="0.6x" class="license_icons"></circle-icon>
          <span class="align-middle">You can modify our product as per your needs and use it for your personal or client projects</span>
        </li>
        <li class="my-25">
          <span class="align-middle">With Single License you will be able to use our product for yourself or your client project for one time. If you want to use it for mulitple times, you need to buy another regular license every time. Ownership and Copyright of our template will stay wuth ThemeSelection after purchasing single license. Tha means you are allowed to use our template in your project and use for one client or yourself.</span>
        </li>
      </ul>
      <div style="display: flex; justify-content: flex-end">
        <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
      >
        Upgrade License
      </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { CircleIcon } from "vue-feather-icons";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    CircleIcon
  },
}
</script>

<style>

</style>
