<template>
  <div class="list-view product-checkout">
    <!-- Left Card -->
    <b-card no-body>
      <b-card-header
        class="flex-column align-items-start"
        style="border-bottom: 1px #e5e7eb solid"
      >
        <b-card-title>Payment Methods</b-card-title>
      </b-card-header>
      <b-card-body class="my-2">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <b-row>
                <b-col>
                  <b-form-radio
                    class="custom-control-info"
                    name="payment-method"
                    value="john-doe-debit-card"
                    checked="john-doe-debit-card"
                  >
                    Card/Debit/ATM Card
                  </b-form-radio>
                </b-col>
                <b-col>
                  <b-form-radio
                    class="custom-control-info"
                    name="payment-method"
                    value="john-doe-debit-card"
                    checked="john-doe-debit-card"
                  >
                    Paypal Account
                  </b-form-radio>
                </b-col>
              </b-row>
            </b-form-group>

            <label for="">Card Number</label>
            <b-input-group class="mb-1">
              <b-form-input
                id="billing_email"
                placeholder="5555-5555-5555-5555"
              />
              <b-input-group-append is-text>
                <img :src="visaImage" style="height: 10px" alt="visa image" />
              </b-input-group-append>
            </b-input-group>

            <b-row>
              <b-col md="6">
                <b-form-group label="Name of Card" label-for="name_of_card">
                  <b-form-input id="name_of_card" placeholder="Name of Card" />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Expiration Date"
                  label-for="expiration_date"
                >
                  <b-form-input id="expiration_date" placeholder="MM/YY" />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Cvv" label-for="expiration_date">
                  <b-form-input id="expiration_date" placeholder="Cvv" />
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-checkbox
              class="custom-control-info"
              checked="true"
              name="check-button"
              switch
              inline
            >
              Save Card for further billing
            </b-form-checkbox>

            <div class="flex-column align-items-start mt-4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
              >
                Save Changes
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
              >
                Discard
              </b-button>
            </div>
          </b-col>
          <b-col md="6">
            <div>My Cards</div>
            <div
              class="mt-2"
              v-for="(item, index) in cards"
              :key="index"
              style="
                border: 1px #e5e7eb solid;
                border-radius: 1rem;
                padding: 1rem;
              "
            >
              <b-row align-h="between">
                <b-col lg="6" >
                  <img
                    :src="item.src"
                    style="height: 35px"
                    alt="Master Image"
                  />
                  <b-row class="mb-1 mt-1" style="padding-left: 10px">
                    <div class="mr-1">Tom Brady</div>
                    <b-badge pill variant="dark">
                      Primary
                    </b-badge>
                  </b-row>
                </b-col>
                <b-col lg="4">
                  <div class="d-flex mb-1">
                    <b-button
                      size="sm"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                    >
                      Edit
                    </b-button>
                    <b-button
                      size="sm"
                      style="margin-left: 4px"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                    >
                      Delete
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row align-h="between">
                <b-col md="4">
                  <div>**** **** **** 9685</div>
                </b-col>
                <b-col md="4">
                  <div>Card expires at 12/12</div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BFormGroup,
  BInputGroup,
  BFormRadio,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BBadge,
  BInputGroupAppend,
} from "bootstrap-vue";

export default {
  components: {
    // BSV
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BBadge,
    BInputGroupAppend,
  },
  data() {
    return {
      masterImage: require("../../../../assets/images/automatum/master-card.png"),
      visaImage: require("../../../../assets/images/automatum/visa.jpg"),
      cards: [
        {
          src: require("../../../../assets/images/automatum/master-card.png"),
        },
        {
          src: require("../../../../assets/images/automatum/visa.jpg"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}
</style>
