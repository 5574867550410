<template>
    <b-tabs>
      <b-tab  active title="Profile">
        <UsersView />
      </b-tab>
      <b-tab
        v-if="$can('read', 'ACL')"
        title="Billing & Plans"
      >
        <BillingPlans />
      </b-tab>
    </b-tabs>
</template>

<script>
import { BCard, BTabs, BTab, BCardText } from 'bootstrap-vue'
import UsersView from "./users-view/UsersView.vue"
import BillingPlans from './billing-plans/BillingPlans.vue';
export default {
  components: {
    BTabs,
    BCardText,
    BTab,
    BCard,
    UsersView,
    BillingPlans
  },
  data() {
    return {
      //
    }
  },
}
</script>
