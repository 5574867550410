<template>
  <b-card no-body>
    <b-card-header class="flex-column align-items-start">
      <b-card-title>Billing Address</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col md="6">
          <!-- basic -->
          <b-form-group label="Company Name" label-for="company_name">
            <b-form-input
              v-model="userData.billing__company"
              id="company_name"
              placeholder="Company Name"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <!-- basic -->
          <b-form-group label="Billing Email" label-for="billing_email">
            <b-form-input
              v-model="userData.billing__email"
              id="billing_email"
              placeholder="Billing Email"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <!-- basic -->
          <b-form-group label="Tax Identification Number or ABN" label-for="tax_in">
            <b-form-input
              v-model="userData.billing__tax"
              id="tax_in"
              placeholder="Tax Identification Number or ABN"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <!-- basic -->
          <b-form-group label="Phone Number" label-for="mobile">
            <b-form-input
              v-model="userData.billing__phone__number"
              id="mobile"
              placeholder="Mobile"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <!-- basic -->
          <b-form-group label="Country" label-for="country">
            <v-select
              v-model="userData.billing__country"
              :options="countryOptions"
              :clearable="false"
              input-id="language"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <!-- basic -->
          <b-form-group label="Billing Address" label-for="billing_address">
            <b-form-input
              v-model="userData.billing__address"
              id="billing_address"
              placeholder="Billing Address"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <!-- basic -->
          <b-form-group label="State" label-for="State">
            <b-form-input
              v-model="userData.billing__state"
              id="state"
              placeholder="State"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <!-- basic -->
          <b-form-group label="Postal / Zip Code" label-for="zip_code">
            <b-form-input
              v-model="userData.billing__code"
              id="zip_code"
              placeholder="Zip Code"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="flex-column align-items-start mt-2">
        <b-button @click="onSubmit" variant="primary" class="mr-1">
          Save Changes
        </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BForm,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";

export default {
  components: {
    // BSV
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    // 3rd party
    vSelect,
  },
  data() {
    return {
      userData: {},
      error: null,
      countryOptions: ["Australia", "United States"],
    };
  },
  computed: {
    userInfo: function () {
      return store.state["app-users"].userInfo;
    },
  },
  mounted() {
    this.userData = this.userInfo
      ? this.userInfo
      : JSON.parse(localStorage.getItem("userData"));
  },
  methods: {
    async onSubmit() {
      try {
        await store.dispatch("app-users/EDIT_USER_INFO", this.userData);
      } catch (error) {
        this.error = error.message;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
